import React from 'react';
import { Card, Grid, CardContent, Typography, Box } from '@mui/material';
import CorendonPrivateRegular from '../../components/dashboard/curacao/sardonyx/corendonprivateregular';
import AirCanadaPrivateRegular from '../../components/dashboard/curacao/sardonyx/aircanadaprivateRegular';
import CSVUploader from '../../components/dashboardupload';

const DashboardPage = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        {/* CSV Uploader */}
        <Grid item xs={12}>
          <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff', marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                Upload CSV Data
              </Typography>
              <CSVUploader />
            </CardContent>
          </Card>
        </Grid>
        {/* Corendon Private vs Regular Transfers */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff', height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                Corendon Private vs Regular Transfers
              </Typography>
              <CorendonPrivateRegular />
            </CardContent>
          </Card>
        </Grid>
        {/* Air Canada Private vs Regular Transfers */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff', height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                Air Canada Private vs Regular Transfers
              </Typography>
              <AirCanadaPrivateRegular />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
