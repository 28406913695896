import React, { useState } from 'react';
import { db } from '../../config/firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import Papa from 'papaparse';

const CSVUploader = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    setUploading(true);
    setError(null);

    Papa.parse(file, {
      complete: async (results) => {
        try {
          const headers = results.data[0];
          const data = results.data.slice(1);

          const sardonyxCollection = collection(db, 'Sardonyx');
          
          await addDoc(sardonyxCollection, {
            headers: headers,
            data: data,
            fileName: file.name,
            uploadDate: new Date().toISOString()
          });

          setFile(null);
          alert('CSV file uploaded successfully!');
        } catch (err) {
          console.error('Error uploading CSV:', err);
          setError('An error occurred while uploading the file. Please try again.');
        } finally {
          setUploading(false);
        }
      },
      error: (err) => {
        console.error('Error parsing CSV:', err);
        setError('Error parsing CSV file. Please make sure it\'s a valid CSV.');
        setUploading(false);
      }
    });
  };

  return (
    <div>
      <h2>Upload CSV File</h2>
      <input type="file" accept=".csv" onChange={handleFileChange} disabled={uploading} />
      <button onClick={handleUpload} disabled={!file || uploading}>
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default CSVUploader;